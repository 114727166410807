<template>
  <a-modal
      :title="title"
      :width="1020"
      :visible="visible"
      :confirmLoading="loading"
      :maskClosable="false"
      :footer="null"
      :destroyOnClose="true"
      @cancel="cancel"
  >
    <a-spin :spinning="loading">
      <div class="table-operator">
        <a-button v-action:add type="primary" @click="handleAdd">申领工具</a-button>
        <SearchForm ref="SearchForm" @handleSubmit="handleSeachSubmit"/>
      </div>
      <s-table
          ref="table"
          rowKey="approve_id"
          :loading="isLoading"
          :columns="columns"
          :data="loadData"
          :pageSize="15"
          :scroll="{y:500}"
      >
        <div slot="tool_list" slot-scope="text, item">
          <div v-for="i in item.tool_list">
            {{ i.tool_name }}(<span style="color: #a60000">￥</span>{{ i.price }}/{{ i.tool_unit }}) x <a>{{
              i.number
            }}</a>
          </div>
        </div>

        <div slot="total_amount" slot-scope="text, item">
          <span style="color: rgb(208, 5, 5)">￥</span>{{ text }}
        </div>
        <div slot="source" slot-scope="text, item">
          {{ text == 1 ? '后台' : '用户' }}
        </div>

        <div class="actions" slot="action" slot-scope="text, item">
<!--          <a v-action:edit @click="handleEdit(item)">编辑</a>-->

          <a-popconfirm
              v-action:delete
              title="确认删除当前记录？"
              ok-text="确定"
              cancel-text="取消"
              @confirm="handleDelete(item)"
          >
            <a href="#">删除</a>
          </a-popconfirm>
        </div>
      </s-table>
      <SaveForm :vehicleId="vehicle_id" ref="SaveForm" @handleSubmit="handleRefresh"/>
    </a-spin>
  </a-modal>
</template>
<script>
import * as Api from '@/api/tool/toolApprove'
import {STable} from "@/components";
import SearchForm from "./modules/SearchForm";
import SaveForm from "./modules/SaveForm";

export default {
  name: "ShowToolList",
  components: {SearchForm, SaveForm, STable},
  data() {
    return {
      title: '配套工具',
      queryParam: {},
      visible: false,
      loading: false,
      isLoading: false,
      vehicle_id: "",
      columns: [
        {
          title: '申领ID',
          dataIndex: 'approve_id',
          align: 'center',
          width: 60,
        },
        {
          title: '工具明细',
          dataIndex: 'tool_list',
          align: 'center',
          scopedSlots: {customRender: 'tool_list'}
        },
        {
          title: '总计金额',
          dataIndex: 'total_amount',
          align: 'center',
          scopedSlots: {customRender: 'total_amount'}
        },
        {
          title: '申领时间',
          dataIndex: 'create_time',
          align: 'center',
        },
        {
          title: '来源',
          dataIndex: 'source',
          align: 'center',
          scopedSlots: {customRender: 'source'},
          width: 60,
        },

        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          scopedSlots: {customRender: 'action'}
        },
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: param => {
        return Api.list({...param, ...this.queryParam})
            .then(response => {
              return response.data.list
            })
      }
    }
  },
  methods: {
    open(vehicle_id, vehicle_name) {
      this.title = vehicle_name + ' - 配套工具'
      this.visible = true
      this.vehicle_id = vehicle_id
    },
    cancel() {
      this.visible = false
    },

    /**
     * 添加
     */
    handleAdd() {
      this.$refs.SaveForm.add()
    },

    /**
     * 编辑
     */
    handleEdit(item) {
      this.$refs.SaveForm.edit(item)
    },

    handleDelete(item) {
      Api.deleted({approveId: item['approve_id']})
          .then((result) => {
            this.$message.success(result.message, 1.5)
            this.handleRefresh()
          })
    },

    /**
     * 搜索
     */
    handleSeachSubmit(value) {
      this.queryParam = {
        ...this.queryParam,
        ...value
      }
      this.handleRefresh(true)
    },


    /**
     * 刷新列表
     * @param Boolean bool 强制刷新到第一页
     */
    handleRefresh(bool = false) {
      this.$refs.table.refresh(bool)
    },
  }
}
</script>
<style scoped lang="less">

</style>
