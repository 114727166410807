import {axios} from '@/utils/request'

const apiPath = {
    vehicle_license: '/identify/getVehicleLicenseInfo',
    road_transport_license: '/identify/getRoadTransportLicenseInfo',
    idcard: '/identify/getIdcardInfo',
    general_basic: '/identify/generalBasic',
    driving_license: '/identify/getDrivingLicenseInfo',
}

/**
 * 识别行驶证信息
 * @param {*} data
 */
export function getVehicleLicenseInfo(data) {
    return axios({
        url: apiPath.vehicle_license,
        method: 'post',
        data
    })
}

/**
 * 识别道路运输证信息
 * @param {*} data
 */
export function getRoadTransportLicenseInfo(data) {
    return axios({
        url: apiPath.road_transport_license,
        method: 'post',
        data
    })
}

/**
 * 识别身份证信息
 * @param {*} data
 */
export function getIdcardInfo(data) {
    return axios({
        url: apiPath.idcard,
        method: 'post',
        data
    })
}

/**
 * 识别文字信息
 * @param {*} data
 */
export function getGeneralBasic(data) {
    return axios({
        url: apiPath.general_basic,
        method: 'post',
        data
    })
}

/**
 * 获取驾驶证信息
 * @param {*} data
 */
export function getDrivingLicenseInfo(data) {
    return axios({
        url: apiPath.driving_license,
        method: 'post',
        data
    })
}
