<template>
  <a-modal
      :title="title"
      :width="720"
      :visible="visible"
      :confirmLoading="confirmLoading"
      :maskClosable="false"
      :centered="true"
      :destroyOnClose="true"
      @ok="handleSubmit"
      @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item label="类型" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-radio-group
              v-decorator="['tire_type', {initialValue:0,rules: [{required: true}]}]"
              button-style="solid"
          >
            <a-radio-button :value="0">
              补/调胎
            </a-radio-button>
            <a-radio-button :value="1">
              新胎更换
            </a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="金额" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-input
              suffix="元"
              placeholder="请输入金额"
              v-decorator="['amount', {rules: [{required: true, message: '请输入金额'}]}]"
          />
        </a-form-item>
        <a-form-item label="金额类型" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-radio-group
              v-decorator="['amount_type', {initialValue:0,rules: [{required: true}]}]"
              button-style="solid"
          >
            <a-radio-button :value="0">
              自行垫付
            </a-radio-button>
            <a-radio-button :value="1">
              公司垫付
            </a-radio-button>
          </a-radio-group>
        </a-form-item>
        <template v-if="form.getFieldValue('tire_type')==1">
          <a-form-item label="更换位置" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-input
                allowClear
                placeholder="请输入更换位置"
                v-decorator="['position', {rules: [{required: true, message: '请输入项更换位置'}]}]"

            />
          </a-form-item>
          <a-form-item label="公里数" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-input
                suffix="km"
                placeholder="请输入公里数"
                v-decorator="['kilometers', {rules: [{required: true, message: '请输入公里数'}]}]"
            />
          </a-form-item>
          <a-form-item label="仪表公里数拍照" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <MultipleUpload
                :file="record.kilometers_file_list ? record.kilometers_file_list : []"
                v-decorator="['kilometers_file_ids',{rules: [{required: true, message: '请至少上传一个仪表公里数拍照'}]}]"
            />
          </a-form-item>
        </template>
        <a-form-item label="单据" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <MultipleUpload
              :file="record.document_file_list ? record.document_file_list : []"
              v-decorator="['document_file_ids',{rules: [{required: true, message: '请至少上传一个单据'}]}]"
          />
        </a-form-item>
        <template v-if="changeRecord.length>0">
          <a-divider orientation="left">
            更改记录
          </a-divider>
          <table class="newtable" style="width: 100%;border-color: #e8e8e8;text-align: center" border>
            <tr style="font-weight: bold">
              <td class="file">更改人</td>
              <td class="file_name">更改内容</td>
              <td class="file_time">更改时间</td>
            </tr>
            <template v-if="changeRecord.length>0">
              <tr v-for="(item,index) in changeRecord">
                <td class="file">
                  {{ item.store_user.real_name }}
                </td>
                <td class="file_name">
                  {{ item.change_text }}
                </td>
                <td class="file_time">
                  {{ item.create_time }}
                </td>
              </tr>
            </template>
            <template v-else>
              <div style="display: flex;justify-content: center;padding: 20px">
                <a-empty
                    :image="require('@/assets/original.png')"
                    :image-style="{height: '60px'}"
                >
                </a-empty>
              </div>
            </template>
          </table>
        </template>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>

import * as Api from '@/api/vehicle/vehicleTire'
import {MultipleUpload} from '@/components'
import _ from "lodash";
import * as changeRecordApi from "@/api/vehicle/changeRecord";

export default {
  components: {
    MultipleUpload
  },
  props: ['vehicleId'],
  data() {
    return {
      // 对话框标题
      title: '',
      // 标签布局属性
      labelCol: {
        span: 7
      },
      // 输入框布局属性
      wrapperCol: {
        span: 13
      },
      // modal(对话框)是否可见
      visible: false,
      // modal(对话框)确定按钮 loading
      confirmLoading: false,
      // 当前表单元素
      form: this.$form.createForm(this),
      // 当前记录
      record: {},
      // 当前模式 add新增 edit编辑
      mode: "",
      changeRecord: [],
    }
  },
  methods: {
    /**
     * 显示对话框
     */
    add() {
      this.mode = "add"
      this.title = '添加保养记录'
      this.visible = true
      this.record = {}
    },
    edit(record) {
      this.mode = "edit"
      this.title = '编辑轮胎记录'
      this.visible = true
      this.record = record
      this.setFieldsValue()
      this.getChangeRecord()
    },


    /**
     * 设置默认值
     */
    setFieldsValue() {
      const {form: {setFieldsValue}} = this
      this.$nextTick(() => {
        const data = _.pick(this.record, ['tire_type', 'position', 'amount', 'amount_type', 'kilometers', 'kilometers_file_ids', 'document_file_ids'])
        setFieldsValue(data)
        this.$nextTick(() => {
          const data = _.pick(this.record, ['position', 'kilometers'])
          setFieldsValue(data)
        })
      })
    },
    async getChangeRecord() {
      this.confirmLoading = true
      const {data: {list}} = await changeRecordApi.getAllList({
        record_id: this.record.vt_id,
        record_type: 2,
      })
      this.confirmLoading = false
      this.changeRecord = list
    },

    /**
     * 确认按钮
     */
    handleSubmit(e) {
      e.preventDefault()
      const {form: {validateFields}} = this
      // 表单验证
      validateFields((errors, values) => {
        // 提交到后端api
        !errors && this.onFormSubmit(values)
      })
    },

    /**
     * 关闭对话框事件
     */
    handleCancel() {
      this.visible = false
      this.form.resetFields()
    },

    /**
     * 提交到后端api
     */
    onFormSubmit(values) {
      this.confirmLoading = true
      var sendApi = null
      if (this.mode == "add") {
        values.vehicle_id = this.vehicleId
        // 标记来源后台
        values.source = 1
        sendApi = Api.add({form: values})
      } else {
        sendApi = Api.edit({vt_id: this.record['vt_id'], form: values})
      }
      sendApi.then((result) => {
        // 显示成功
        this.$message.success(result.message, 1.5)
        // 关闭对话框
        this.handleCancel()
        // 通知父端组件提交完成了
        this.$emit('handleSubmit', values)
      })
          .finally((result) => {
            this.confirmLoading = false
          })
    }

  }
}
</script>
