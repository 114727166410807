<template>
  <a-modal
      :title="title"
      :width="1370"
      :visible="visible"
      :confirmLoading="loading"
      :maskClosable="false"
      :destroyOnClose="true"
      :footer="null"
      @cancel="cancel"
  >
    <a-spin :spinning="loading">
<!--      <a-tabs default-active-key="1">-->
<!--        <a-tab-pane key="1" tab="预申报记录">-->
<!--          &lt;!&ndash;          预申报记录&ndash;&gt;-->
<!--          <pred :vehicleId="vehicle_id" :pd_type="0"/>-->
<!--        </a-tab-pane>-->
<!--        <a-tab-pane key="2" tab="已上传加油记录" force-render>-->
          <!--          已上传加油记录-->
          <div class="table-operator">
            <SearchForm ref="SearchForm" @handleSubmit="handleSeachSubmit"/>
            <b>当前油耗：<a>{{ yh }} L/百公里</a></b>
          </div>
          <s-table
              ref="table"
              rowKey="vr_id"
              :loading="isLoading"
              :columns="columns"
              :data="loadData"
              :pageSize="15"
              :scroll="{y:500}"
          >
            <div slot="jyd" slot-scope="text, item">
              <b>{{ text == 0 ? '仓库' : '加油站' }}</b>
            </div>

            <div slot="jflx" slot-scope="text, item">
              <b v-if="item.jyd==1">{{ text == 0 ? '油卡加油' : '现金加油' }}</b>
              <b v-else>无</b>
            </div>
            <div slot="status" slot-scope="text, item">
              <a-tag v-if="item.status==0">待审核</a-tag>
              <a-tag color="green" v-if="item.status==1">审核通过</a-tag>
              <template v-if="item.status==2">
                <a-tag color="red">
                  审核驳回
                </a-tag><br>
                <span style="color: red">{{ item.reject_text }}</span>
              </template>
            </div>
            <div slot="number" slot-scope="text, item">
              {{ text }}{{ item.tool_unit || 'L' }}
              <a-tag color="green" v-if="item.status_type==1">起始标记</a-tag>
              <a-tag color="blue" v-if="item.status_type==2">结束标记</a-tag>
            </div>
            <div slot="price" slot-scope="text, item">
              <span style="color: rgb(208, 5, 5)">￥</span>{{ text }}/{{ item.tool_unit }}
            </div>
            <div slot="total_amount" slot-scope="text, item">
              <span style="color: rgb(208, 5, 5)">￥</span>{{ text }}
              <template v-if="item.jyd==1">
                <a-tag color="green" v-if="item.amount_type==0">自行垫付</a-tag>
                <a-tag color="blue" v-if="item.amount_type==1">公司已付</a-tag>
              </template>
            </div>

            <div slot="source" slot-scope="text, item">
              {{ text == 1 ? '后台' : '用户' }}
            </div>
            <div slot="kilometers" slot-scope="text, item">
              <div v-if="text">{{ text }}km</div>
            </div>

            <div class="actions" slot="action" slot-scope="text, item">
              <a style="color: red" v-if="item.status_type!=0" @click="handlebiaoji(item,0)">取消标记</a>
              <template v-if="item.status_type==0">
                <a v-if="item.bj_status===3 || item.bj_status===0"
                   @click="handlebiaoji(item,1)">起始标记</a>
                <a v-if="item.bj_status===2 || item.bj_status===0"
                   @click="handlebiaoji(item,2)">结束标记</a>
              </template>
              <template v-if="item.status==0">
                <a
                   @click="handleAuditPass(item,1)">审核通过</a>
                <a
                   @click="handleAuditNoPass(item,2)">审核不通过</a>
              </template>
              <a @click="handleEdit(item)">编辑</a>
              <a-popconfirm
                  title="确认删除当前记录？"
                  ok-text="确定"
                  cancel-text="取消"
                  @confirm="handleDelete(item)"
              >
                <a href="#">删除</a>
              </a-popconfirm>
            </div>
          </s-table>
          <SaveForm :vehicleId="vehicle_id" ref="SaveForm" @handleSubmit="handleRefresh"/>
          <FillRejectText ref="FillRejectText" @handleSuccess="onAuditNoPass"/>
<!--        </a-tab-pane>-->
<!--      </a-tabs>-->
    </a-spin>
  </a-modal>
</template>
<script>
import * as Api from '@/api/vehicle/refuelLog'
import {STable, FillRejectText} from "@/components";
import SearchForm from "./modules/SearchForm";
import SaveForm from "./modules/SaveForm";
import pred from '@/views/vehicle/index/pre/Index.vue'

export default {
  name: "ShowToolList",
  components: {SearchForm, FillRejectText, SaveForm, STable, pred},
  data() {
    return {
      yh: 0,
      start_vr_id: 0,
      end_vr_id: 0,
      title: '配套工具',
      queryParam: {},
      visible: false,
      loading: false,
      isLoading: false,
      vehicle_id: "",
      columns: [
        {
          title: '审核状态',
          dataIndex: 'status',
          align: 'center',
          width: 100,
          scopedSlots: {customRender: 'status'}
        },
        {
          title: '加油点',
          dataIndex: 'jyd',
          align: 'center',
          width: 100,
          scopedSlots: {customRender: 'jyd'}
        },

        {
          title: '缴费类型',
          dataIndex: 'jflx',
          align: 'center',
          width: 100,
          scopedSlots: {customRender: 'jflx'}
        },
        {
          title: '加油数量',
          dataIndex: 'jysl',
          align: 'center',
          width: 100,
          scopedSlots: {customRender: 'number'}
        },
        {
          title: '加油金额',
          dataIndex: 'amount',
          align: 'center',
          width: 160,
          scopedSlots: {customRender: 'total_amount'}
        },
        {
          title: '车辆仪表公里数',
          dataIndex: 'kilometers',
          align: 'center',
          width: 100,

          scopedSlots: {customRender: 'kilometers'}
        },
        {
          title: '加油时间',
          dataIndex: 'create_time',
          align: 'center',
          width: 200,
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          fixed: 'right',
          width: 340,
          scopedSlots: {customRender: 'action'}
        },
      ],
      currVrId: '',
      // 加载数据方法 必须为 Promise 对象
      loadData: param => {
        return Api.list({...param, ...this.queryParam})
            .then(response => {
              this.yh = response.data.yh
              this.start_vr_id = response.data.start_vr_id
              this.end_vr_id = response.data.end_vr_id
              return response.data.list
            })
      }
    }
  },
  methods: {
    open(vehicle_id, vehicle_name) {
      this.title = vehicle_name + ' - 加油记录'
      this.visible = true
      this.vehicle_id = vehicle_id
      this.$nextTick(() => {
        this.handleRefresh(true)
      })

    },
    cancel() {
      this.visible = false
    },

    /**
     * 添加
     */
    handleAdd() {
      this.$refs.SaveForm.add()
    },

    /**
     * 编辑
     */
    handleEdit(item) {
      this.$refs.SaveForm.edit(item)
    },
    handlebiaoji(item, val) {
      Api.biaoji({vrId: item['vr_id'], status_type: val, start_vr_id: this.start_vr_id, end_vr_id: this.end_vr_id})
          .then((result) => {
            this.$message.success(result.message, 1.5)
            this.handleRefresh()
          })
    },
    handleAuditPass(item, val) {
      Api.audit({vrId: item['vr_id'], form: {status: val}})
          .then((result) => {
            this.$message.success(result.message, 1.5)
            this.handleRefresh()
          })
    },

    handleAuditNoPass(item, val) {
      this.currVrId = item.vr_id
      this.$refs.FillRejectText.open()
      // Api.audit({vrId: item['vr_id'], form:{status: val}})
      //     .then((result) => {
      //       this.$message.success(result.message, 1.5)
      //       this.handleRefresh()
      //     })
    },

    onAuditNoPass(text) {
      Api.audit({vrId: this.currVrId, form: {status: 2, reject_text: text}})
          .then((result) => {
            this.$message.success(result.message, 1.5)
            this.handleRefresh()
          })
    },

    handleDelete(item) {
      Api.deleted({vrId: item['vr_id']})
          .then((result) => {
            this.$message.success(result.message, 1.5)
            this.handleRefresh()
          })
    },

    /**
     * 搜索
     */
    handleSeachSubmit(value) {
      this.queryParam = {
        ...this.queryParam,
        ...value
      }
      this.handleRefresh(true)
    },


    /**
     * 刷新列表
     * @param Boolean bool 强制刷新到第一页
     */
    handleRefresh(bool = false) {
      this.$refs.table.refresh(bool)
    },
  }
}
</script>
<style scoped lang="less">

</style>
