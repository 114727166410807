<template>
  <a-modal
      :title="title"
      :width="720"
      :visible="visible"
      :confirmLoading="confirmLoading"
      :maskClosable="false"
      :destroyOnClose="true"
      @ok="handleSubmit"
      @cancel="handleCancel"
  >
    <a-form :form="form">
      <a-alert type="success" :description="description"/>
      <a-form-item style="margin-top: 20px" label="车位数量" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input-number v-model="pay_number" :min="1" :max="10"/>
      </a-form-item>

      <template v-for="(item,index) in vehicleList">
        <a-form-item :label="'车位'+(index+1)" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <div style="display: flex;align-items: center">
            <a-input v-model="item.vehicle_name" placeholder="请填写当前车位对应车牌号"/>
            <div style="width: 100px"><a style="margin-left: 10px;color: red" @click="delFile(index)">删除</a></div>
          </div>
        </a-form-item>
      </template>
    </a-form>
  </a-modal>
</template>
<script>
import {UploadFile, UploadImage} from "@/components";
import * as cardApi from '@/api/store/card'
import * as Api from '@/api/vehicle'

export default {
  components: {
    UploadImage,
    UploadFile
  },
  data() {
    return {
      title: '增加车位',
      labelCol: {span: 5},
      // 输入框布局属性
      wrapperCol: {span: 14},
      // modal(对话框)是否可见
      visible: false,
      // modal(对话框)确定按钮 loading
      confirmLoading: false,
      // 当前表单元素
      form: this.$form.createForm(this),
      // 当前记录
      record: {},


      pay_number: 1,
      description: '',
      trial: {},
      vehicleList: [
        {vehicle_name: ''}
      ],
      isAny: true
    }
  },
  watch: {
    pay_number(newValue, oldValue) {
      if (!this.isAny) {
        return
      }
      const n = newValue - oldValue
      if (n < 0) {
        this.vehicleList.splice(n)
      } else {
        for (var i = 0; i < n; i++) {
          this.vehicleList.push(
              {vehicle_name: ''}
          )
        }
      }
    }
  },

  methods: {
    open() {
      this.getTrial()
      this.visible = true
    },
    async getTrial() {
      const {data: {trial}} = await cardApi.isTrial()
      this.trial = trial
      this.description = '您已领取免费试用，当前不限制车位数量。\n试用时间：' + trial.start_time_text + ' - ' + trial.end_time_text
    },
    delFile(index) {
      if (this.vehicleList.length <= 1) {
        this.$message.error('至少保留一项')
        return
      }
      this.isAny = false
      this.vehicleList.splice(index, 1)
      this.pay_number--

      setTimeout(() => {
        this.isAny = true
      }, 500)
    },

    async handleSubmit() {
      const {message} = await Api.addTrialVehicle({form: this.vehicleList})
      // 显示成功
      this.$message.success(message, 1.5)
      // 关闭对话框
      this.handleCancel()
      // 通知父端组件提交完成了
      this.$emit('handleSubmit')
    },
    handleCancel() {
      this.visible = false
      // this.pay_number = 1
      // this.vehicleList[]
    },
  }
}
</script>
<style scoped lang="less">

</style>
