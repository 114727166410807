<template>
  <a-card :bordered="false">
    <div class="card-title">{{ $route.meta.title }}</div>
    <div class="table-operator">
      <a-button v-action:add type="primary" icon="plus" @click="handleAdd">增加车位</a-button>
      <a-button style="margin-left: 10px" v-action:export type="primary" icon="export" @click="handleExport">导出车辆
      </a-button>
      <SearchForm ref="SearchForm" @handleSubmit="handleSeachSubmit"/>
    </div>
    <div>
      <a>总计车辆：{{ total }}</a>
    </div>
    <a-divider orientation="left">车辆列表</a-divider>
    <a-spin :spinning="isLoading">
      <div class="list" style="display: flex;flex-wrap: wrap">
        <div
            class="list-item"
            v-for="item in list"
            @click="handleEdit(item)"
        >
          <a-tooltip placement="top">
            <template slot="title">
              <div style="margin-bottom: 5px">牵引车行驶证：
                <a-tag color="green" v-if="item.document_status_list.ctxsz==0">正常</a-tag>
                <a-tag color="orange" v-if="item.document_status_list.ctxsz==1">即将过期</a-tag>
                <a-tag color="red" v-if="item.document_status_list.ctxsz==2">已过期</a-tag>
              </div>
              <div style="margin-bottom: 5px">牵引车道路运输证：
                <a-tag color="green" v-if="item.document_status_list.ctysz==0">正常</a-tag>
                <a-tag color="orange" v-if="item.document_status_list.ctysz==1">即将过期</a-tag>
                <a-tag color="red" v-if="item.document_status_list.ctysz==2">已过期</a-tag>
              </div>
              <br>
              <div style="margin-bottom: 5px">挂车行驶证：
                <a-tag color="green" v-if="item.document_status_list.gcxsz==0">正常</a-tag>
                <a-tag color="orange" v-if="item.document_status_list.gcxsz==1">即将过期</a-tag>
                <a-tag color="red" v-if="item.document_status_list.gcxsz==2">已过期</a-tag>
              </div>
              <div style="margin-bottom: 5px">挂车道路运输证：
                <a-tag color="green" v-if="item.document_status_list.gcysz==0">正常</a-tag>
                <a-tag color="orange" v-if="item.document_status_list.gcysz==1">即将过期</a-tag>
                <a-tag color="red" v-if="item.document_status_list.gcysz==2">已过期</a-tag>
              </div>
              <br>
              <div style="margin-bottom: 5px" v-for="i2 in item.document_status_list.qtzj">
                {{ i2.file_name }}：
                <a-tag color="green" v-if="i2.status==0">正常</a-tag>
                <a-tag color="orange" v-if="i2.status==1">即将过期</a-tag>
                <a-tag color="red" v-if="i2.status==2">已过期</a-tag>
              </div>
            </template>
            <img class="cover" src="@/assets/vehicle.png">
            <div class="info-box">
              <div class="info-line">
                <div class="lable">牵引车车号</div>
                <div class="value">{{ item.cl ? item.cl.xsz_hphm : '' }}</div>
              </div>
              <div class="info-line">
                <div class="lable">挂车车号</div>
                <div class="value">{{ item.gc ? item.gc.xsz_hphm : '' }}</div>
              </div>
              <div class="info-line">
                <div class="lable">司机</div>
                <div class="value"><b>{{ item.driver ? item.driver.personnel_name : '-' }}{{ item.driver2 ? ' - '+item.driver2.personnel_name : '' }}</b></div>
              </div>
              <div class="info-line">
                <div class="lable">押运员</div>
                <div class="value"><b>{{ item.supercargo ? item.supercargo.personnel_name : '-' }}</b></div>
              </div>
            </div>
          </a-tooltip>
        </div>
      </div>
      <div>
        <a-pagination
            v-model="queryParam.page"
            :total="total"
            :defaultPageSize="16"
            @change="onPageChange"
            @showSizeChange="onShowSizeChange"
        />
      </div>
    </a-spin>
    <!--    <s-table-->
    <!--        ref="table"-->
    <!--        rowKey="vehicle_id"-->
    <!--        :loading="isLoading"-->
    <!--        :columns="columns"-->
    <!--        :data="loadData"-->
    <!--        :pageSize="15"-->
    <!--        :scroll="{x:true}"-->
    <!--    >-->

    <!--      <div slot="vehicle_name" slot-scope="text, item">-->
    <!--        <b>{{ text }}</b>-->
    <!--      </div>-->
    <!--      <div slot="driver" slot-scope="text, item">-->
    <!--        <template v-if="item.driver">-->
    <!--          <b>{{ item.driver ? item.driver.personnel_name : '' }}</b>(<a>{{ item.driver ? item.driver.mobile : '' }}</a>)-->
    <!--        </template>-->
    <!--        <template v-else>-->
    <!--          <a style="color: red">未绑定</a>-->
    <!--        </template>-->
    <!--      </div>-->
    <!--      <div slot="supercargo" slot-scope="text, item">-->
    <!--        <template v-if="item.supercargo">-->
    <!--          <b>{{-->
    <!--              item.supercargo ? item.supercargo.personnel_name : ''-->
    <!--            }}</b>(<a>{{ item.supercargo ? item.supercargo.mobile : '' }}</a>)-->
    <!--        </template>-->
    <!--        <template v-else>-->
    <!--          <a style="color: red">未绑定</a>-->
    <!--        </template>-->
    <!--      </div>-->
    <!--      <div slot="document_status" slot-scope="text, item">-->

    <!--        <a-tooltip placement="topLeft">-->
    <!--          <template slot="title">-->
    <!--            <div style="margin-bottom: 5px">牵引车行驶证：-->
    <!--              <a-tag color="green" v-if="item.document_status_list.ctxsz==0">正常</a-tag>-->
    <!--              <a-tag color="orange" v-if="item.document_status_list.ctxsz==1">即将过期</a-tag>-->
    <!--              <a-tag color="red" v-if="item.document_status_list.ctxsz==2">已过期</a-tag>-->
    <!--            </div>-->
    <!--            <div style="margin-bottom: 5px">牵引车道路运输证：-->
    <!--              <a-tag color="green" v-if="item.document_status_list.ctysz==0">正常</a-tag>-->
    <!--              <a-tag color="orange" v-if="item.document_status_list.ctysz==1">即将过期</a-tag>-->
    <!--              <a-tag color="red" v-if="item.document_status_list.ctysz==2">已过期</a-tag>-->
    <!--            </div>-->
    <!--            <br>-->
    <!--            <div style="margin-bottom: 5px">挂车行驶证：-->
    <!--              <a-tag color="green" v-if="item.document_status_list.gcxsz==0">正常</a-tag>-->
    <!--              <a-tag color="orange" v-if="item.document_status_list.gcxsz==1">即将过期</a-tag>-->
    <!--              <a-tag color="red" v-if="item.document_status_list.gcxsz==2">已过期</a-tag>-->
    <!--            </div>-->
    <!--            <div style="margin-bottom: 5px">挂车道路运输证：-->
    <!--              <a-tag color="green" v-if="item.document_status_list.gcysz==0">正常</a-tag>-->
    <!--              <a-tag color="orange" v-if="item.document_status_list.gcysz==1">即将过期</a-tag>-->
    <!--              <a-tag color="red" v-if="item.document_status_list.gcysz==2">已过期</a-tag>-->
    <!--            </div>-->
    <!--            <br>-->
    <!--            <div style="margin-bottom: 5px">牵引车商业险：-->
    <!--              <a-tag color="green" v-if="item.document_status_list.ctsyx==0">正常</a-tag>-->
    <!--              <a-tag color="orange" v-if="item.document_status_list.ctsyx==1">即将过期</a-tag>-->
    <!--              <a-tag color="red" v-if="item.document_status_list.ctsyx==2">已过期</a-tag>-->
    <!--            </div>-->
    <!--            <div style="margin-bottom: 5px">牵引车强制险：-->
    <!--              <a-tag color="green" v-if="item.document_status_list.ctqzx==0">正常</a-tag>-->
    <!--              <a-tag color="orange" v-if="item.document_status_list.ctqzx==1">即将过期</a-tag>-->
    <!--              <a-tag color="red" v-if="item.document_status_list.ctqzx==2">已过期</a-tag>-->
    <!--            </div>-->
    <!--            <div style="margin-bottom: 5px">牵引车承运险：-->
    <!--              <a-tag color="green" v-if="item.document_status_list.ctcyx==0">正常</a-tag>-->
    <!--              <a-tag color="orange" v-if="item.document_status_list.ctcyx==1">即将过期</a-tag>-->
    <!--              <a-tag color="red" v-if="item.document_status_list.ctcyx==2">已过期</a-tag>-->
    <!--            </div>-->
    <!--            <br>-->
    <!--            <div style="margin-bottom: 5px">挂车商业险：-->
    <!--              <a-tag color="green" v-if="item.document_status_list.gcsyx==0">正常</a-tag>-->
    <!--              <a-tag color="orange" v-if="item.document_status_list.gcsyx==1">即将过期</a-tag>-->
    <!--              <a-tag color="red" v-if="item.document_status_list.gcsyx==2">已过期</a-tag>-->
    <!--            </div>-->
    <!--            <div style="margin-bottom: 5px">挂车强制险：-->
    <!--              <a-tag color="green" v-if="item.document_status_list.gcqzx==0">正常</a-tag>-->
    <!--              <a-tag color="orange" v-if="item.document_status_list.gcqzx==1">即将过期</a-tag>-->
    <!--              <a-tag color="red" v-if="item.document_status_list.gcqzx==2">已过期</a-tag>-->
    <!--            </div>-->
    <!--            <div style="margin-bottom: 5px">挂车承运险：-->
    <!--              <a-tag color="green" v-if="item.document_status_list.gccyx==0">正常</a-tag>-->
    <!--              <a-tag color="orange" v-if="item.document_status_list.gccyx==1">即将过期</a-tag>-->
    <!--              <a-tag color="red" v-if="item.document_status_list.gccyx==2">已过期</a-tag>-->
    <!--            </div>-->
    <!--            <br>-->
    <!--            <div style="margin-bottom: 5px">其他证件：-->
    <!--              <a-tag color="green">正常</a-tag>-->
    <!--            </div>-->
    <!--          </template>-->
    <!--          <a-tag color="green" v-if="text==0">正常</a-tag>-->
    <!--          <a-tag color="orange" v-if="text==1">即将过期</a-tag>-->
    <!--          <a-tag color="red" v-if="text==2">已过期</a-tag>-->
    <!--        </a-tooltip>-->

    <!--      </div>-->
    <!--      <div slot="status" slot-scope="text, item">-->
    <!--        <a-tag color="green" v-if="text===0">启用</a-tag>-->
    <!--        <a-tag color="red" v-else>停用</a-tag>-->
    <!--      </div>-->
    <!--      <div class="actions" slot="action" slot-scope="text, item">-->
    <!--        <a-dropdown v-action:scjl>-->
    <!--          <a class="ant-dropdown-link" @click="e => e.preventDefault()">-->
    <!--            随车记录-->
    <!--            <a-icon type="down"/>-->
    <!--          </a>-->
    <!--          <a-menu slot="overlay">-->
    <!--            <a-menu-item v-action:show3d @click="handle3DShow(item)">-->
    <!--              <a href="javascript:;">3D展示</a>-->
    <!--            </a-menu-item>-->
    <!--            <a-menu-item v-action:showRefuelLog @click="handleShowModel(item,'ShowRefuelLog')">-->
    <!--              <a href="javascript:;">加油记录</a>-->
    <!--            </a-menu-item>-->
    <!--            <a-menu-item v-action:showUreaLog @click="handleShowModel(item,'ShowUreaLog')">-->
    <!--              <a href="javascript:;">尿素记录</a>-->
    <!--            </a-menu-item>-->
    <!--            <a-menu-item v-action:showMaintenanceLog @click="handleShowModel(item,'ShowMaintenanceLog')">-->
    <!--              <a href="javascript:;">保养记录</a>-->
    <!--            </a-menu-item>-->
    <!--            <a-menu-item v-action:showWeixiuLog @click="handleShowModel(item,'ShowWeixiuLog')">-->
    <!--              <a href="javascript:;">维修记录</a>-->
    <!--            </a-menu-item>-->
    <!--            <a-menu-item v-action:showTireLog @click="handleShowModel(item,'ShowTireLog')">-->
    <!--              <a href="javascript:;">轮胎记录</a>-->
    <!--            </a-menu-item>-->
    <!--            <a-menu-item v-action:showHandoverLog @click="handleShowModel(item,'ShowHandoverLog')">-->
    <!--              <a href="javascript:;">交车记录</a>-->
    <!--            </a-menu-item>-->
    <!--            <a-menu-item v-action:showAccidentLog @click="handleShowModel(item,'ShowAccidentLog')">-->
    <!--              <a href="javascript:;">事故记录</a>-->
    <!--            </a-menu-item>-->
    <!--          </a-menu>-->
    <!--        </a-dropdown>-->
    <!--        <a v-action:edit @click="handleEdit(item)">车辆信息详情</a>-->
    <!--        <a v-action:delete @click="handleDelete(item)">删除</a>-->
    <!--      </div>-->
    <!--    </s-table>-->
    <SaveForm ref="SaveForm" @handleSubmit="handleRefresh"/>
    <ShowToolList ref="ShowToolList" @handleSubmit="handleRefresh"/>
    <ShowRefuelLog ref="ShowRefuelLog" @handleSubmit="handleRefresh"/>
    <ShowUreaLog ref="ShowUreaLog" @handleSubmit="handleRefresh"/>
    <ShowMaintenanceLog ref="ShowMaintenanceLog" @handleSubmit="handleRefresh"/>
    <ShowWeixiuLog ref="ShowWeixiuLog" @handleSubmit="handleRefresh"/>
    <ShowTireLog ref="ShowTireLog" @handleSubmit="handleRefresh"/>
    <ShowHandoverLog ref="ShowHandoverLog" @handleSubmit="handleRefresh"/>
    <AddTrialForm ref="AddTrialForm" @handleSubmit="handleRefresh"/>
    <MemberPackage ref="MemberPackage" @handleSubmit="handleRefresh"/>
    <showMpCode ref="showMpCode" @handleSubmit="handleRefresh"/>
    <ShowAccidentLog ref="ShowAccidentLog" @handleSubmit="handleRefresh"/>
  </a-card>
</template>

<script>
import * as Api from '@/api/vehicle'
import * as cardApi from '@/api/store/card'
import {MemberPackage, STable} from '@/components'
import showMpCode from '@/components/showMpCode/Index.vue'
import SaveForm from './modules/SaveForm'
import SearchForm from './modules/SearchForm'
import AddTrialForm from './modules/AddTrialForm.vue'
import ShowToolList from './tooApprove/Index'
import ShowRefuelLog from './refuelLog/Index'
import ShowUreaLog from './ureaLog/Index'
import ShowMaintenanceLog from './maintenanceLog/Index'
import ShowWeixiuLog from './weixiuLog/Index'
import ShowTireLog from './tireLog/Index'
import ShowHandoverLog from './handoverLog/Index'
import ShowAccidentLog from './accidentLog/Index'

export default {
  name: 'vehicle',
  components: {
    STable,
    SaveForm,
    SearchForm,
    ShowToolList,
    ShowRefuelLog,
    ShowUreaLog,
    ShowMaintenanceLog,
    ShowWeixiuLog,
    ShowTireLog,
    ShowHandoverLog,
    ShowAccidentLog,
    AddTrialForm,
    MemberPackage,
    showMpCode
  },
  data() {
    return {
      // 查询参数
      queryParam: {
        page: 1
      },
      // 正在加载
      isLoading: false,
      // 表头
      columns: [

        {
          title: '车辆编号',
          dataIndex: 'number',
          align: 'center',
          width: 60
        },
        {
          title: '证件状态',
          dataIndex: 'document_status',
          width: 80,
          scopedSlots: {customRender: 'document_status'}
        },
        {
          title: '车辆状态',
          dataIndex: 'status',
          scopedSlots: {customRender: 'status'},
          align: 'center',
        },
        {
          title: '牵引车车号',
          dataIndex: 'cl.xsz_hphm',
          scopedSlots: {customRender: 'vehicle_name'},
          align: 'center',
        },
        {
          title: '挂车车号',
          dataIndex: 'gc.xsz_hphm',
          scopedSlots: {customRender: 'vehicle_name'},
          align: 'center',
        },
        {
          title: '司机',
          dataIndex: 'driver',
          scopedSlots: {customRender: 'driver'},
        },
        {
          title: '押运员',
          dataIndex: 'supercargo',
          scopedSlots: {customRender: 'supercargo'},
        },

        {
          title: '添加时间',
          dataIndex: 'create_time',
          align: 'center',
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 180,
          align: 'center',
          scopedSlots: {customRender: 'action'}
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: param => {
        return Api.list({...param, ...this.queryParam})
            .then(response => {
              return response.data.list
            })
      },
      list: [],
      total: 0
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      this.isLoading = true
      const {data: {list}} = await Api.list(this.queryParam)
      this.list = list.data
      this.total = list.total
      this.isLoading = false

    },
    onPageChange(e) {
      this.getList()
    },
    onShowSizeChange(e) {
      console.log(e)
    },

    async isTrial() {
      const {data: {trial}} = await cardApi.isTrial()
      if (trial.card_id !== undefined) {
        this.$refs.AddTrialForm.open()
      } else {
        //前往购买
        this.$refs.showMpCode.show()
      }
    },

    /**
     * 新增记录
     */
    handleAdd() {
      this.isTrial()
      // this.$refs.SaveForm.add()
    },

    /**
     * 导出车辆
     */
    async handleExport() {
      const values = this.$refs.SearchForm.getFormValues()
      console.log(values)

      const {data: {url}} = await Api.exportData({...values, ...this.queryParam})
      this.downloadFile(url)
    },

    downloadFile(url) {
      try {
        let elementIftrame = document.createElement("iframe");
        elementIftrame.src = url;
        elementIftrame.style.display = "none";
        document.body.appendChild(elementIftrame);
      } catch (error) {
        console.log(error);
      }
    },

    handle3DShow(item) {
      this.$router.push({
        path: '/vehicle/3d', query: {
          vehicle_id: item.vehicle_id,
          vehicle_name: item.vehicle_name
        }
      })
    },
    /**
     * 编辑记录
     */
    handleEdit(item) {
      this.$refs.SaveForm.edit(item)
    },

    /**
     * 通用Model
     */
    handleShowModel({vehicle_id, vehicle_name}, model) {
      this.$refs[model].queryParam.vehicle_id = vehicle_id
      this.$refs[model].open(vehicle_id, vehicle_name)
    },


    /**
     * 删除记录
     */
    handleDelete(item) {
      const app = this
      const modal = this.$confirm({
        title: '您确定要删除该记录吗?',
        content: '删除后不可恢复',
        onOk() {
          return Api.deleted({vehicleId: item['vehicle_id']})
              .then((result) => {
                app.$message.success(result.message, 1.5)
                app.handleRefresh()
              })
              .finally(result => {
                modal.destroy()
              })
        }
      })
    },

    /**
     * 搜索
     */
    handleSeachSubmit(value) {
      this.queryParam = {
        ...this.queryParam,
        ...value
      }
      this.getList()
    },

    /**
     * 刷新列表
     * @param Boolean bool 强制刷新到第一页
     */
    handleRefresh(bool = false) {
      this.getList()
      // this.$refs.table.refresh(bool)
    },

  }
}
</script>
<style lang="less" scoped>
.list {
  display: flex;
  //justify-content: space-between;
}

.list-item {
  width: 330px;
  margin-bottom: 20px;
  position: relative;
  cursor: pointer;
  //margin-left: 20px;
  .cover {
    width: 100%;
  }

  .info-box {
    position: absolute;
    top: 5px;
    left: 22px;

    .info-line {
      font-size: 12px;
      display: flex;
      margin-bottom: 1px;

      .lable {
        margin-right: 6px;
        text-align: center;
        width: 70px;
        border-radius: 5px;
        background: #0482CB;
        color: #FFFFFF;
      }

      .value {
        font-weight: bold;
      }

    }
  }

}
</style>
